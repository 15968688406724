import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { CockpitCategorySummary, CockpitPage, TaskDepartment } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, Team } from '@dougs/user/dto';
import { CockpitActionComponentService } from './cockpit-action.component.service';

@Injectable()
export class CockpitCategoriesComponentService {
  public selectedCodeOrCategory: string | null = null;

  readonly unwrappedCategoryCodes: Set<string> = new Set<string>();

  private readonly categorySummariesSource: BehaviorSubject<CockpitCategorySummary[]> = new BehaviorSubject<
    CockpitCategorySummary[]
  >([]);
  private readonly totalTaskCountSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly isLoadingCategoriesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly totalTaskCount$: Observable<number> = this.totalTaskCountSource.asObservable();
  readonly isLoadingCategories$: Observable<boolean> = this.isLoadingCategoriesSource.asObservable();
  readonly categorySummaries$: Observable<CockpitCategorySummary[]> = this.categorySummariesSource.asObservable();

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitStateService: CockpitStateService,
  ) {}

  readonly refreshCategories$: Observable<unknown> = combineLatest([
    this.cockpitStateService.page$,
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.team$,
    this.cockpitStateService.department$,
    this.cockpitStateService.filter$,
    this.cockpitStateService.quoteStatus$,
    this.cockpitStateService.domain$,
    this.cockpitStateService.partition$,
    this.cockpitActionComponentService.actionAssignation$,
  ]).pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    filter(([page, collaborator, team, department]) =>
      this.shouldRefreshCategories(page, collaborator, team, department),
    ),
    debounceTime(50),
    tap(() => this.isLoadingCategoriesSource.next(true)),
    switchMap(([page, collaborator, team, department, filter, quoteStatus, domain, partition]) =>
      this.cockpitStateService.getSummaries({
        collaborator,
        team,
        department,
        filter,
        quoteStatus,
        domain,
        page,
        partition,
      }),
    ),
    map((summaries: CockpitCategorySummary[]) => this.sortByCodeTitle(summaries)),
    tap((summaries: CockpitCategorySummary[]) => this.categorySummariesSource.next(summaries)),
    tap((summaries: CockpitCategorySummary[]) =>
      this.totalTaskCountSource.next(summaries.reduce((sum, summary) => sum + summary.count, 0)),
    ),
    tap(() => this.isLoadingCategoriesSource.next(false)),
  );

  private shouldRefreshCategories(
    page: CockpitPage,
    collaborator: Readonly<Collaborator> | null,
    team: Readonly<Team> | null,
    department: TaskDepartment | null,
  ): boolean {
    if (collaborator === null && team === null && department === null) {
      return false;
    }

    return page !== 'unseen' && page !== 'mentions';
  }

  private sortByCodeTitle(summaries: Readonly<CockpitCategorySummary[]>): CockpitCategorySummary[] {
    return [...summaries]
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((summary) => {
        summary.tasks.sort((a, b) => a.title.localeCompare(b.title));
        return summary;
      });
  }

  readonly resetSelectedCategoryAndCode$: Observable<unknown> =
    this.cockpitActionComponentService.onCockpitTabChanged$.pipe(
      filter((action) => !!action),
      skip(1),
      tap(() => this.cockpitStateService.resetCodeState()),
      tap(() => this.cockpitStateService.resetCategoryState()),
    );

  toggleWrapInMemory(code: string): void {
    if (this.unwrappedCategoryCodes.has(code)) {
      this.unwrappedCategoryCodes.delete(code);
    } else {
      this.unwrappedCategoryCodes.add(code);
    }
  }

  async updateCodeOrCategory(
    codeOrCategory: {
      type: 'code' | 'category';
      value: string;
    } | null,
  ): Promise<void> {
    const category: string | null = codeOrCategory && codeOrCategory.type === 'category' ? codeOrCategory.value : null;
    const code: string | null = codeOrCategory && codeOrCategory.type === 'code' ? codeOrCategory.value : null;

    this.cockpitStateService.updateCategoryState(category);
    this.cockpitStateService.updateCodeState(code);

    this.selectedCodeOrCategory = code || category;
  }
}
